<template>
  <div>
    <vs-card
      v-if="logged_in_spoc_id == 132 || logged_in_spoc_id == 100 || logged_in_spoc_id == 937 || logged_in_spoc_id == 39"
      class="roadmap-filter-card mb4">
      <vs-row>
        <vs-col style="font-size: 14px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1"><b>Webinar
            List : </b></vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
          <div>
            <treeselect :multiple="true" :options="webinars_list" placeholder="Search by webinar topic"
              v-model="selected_webinar" />
          </div>
        </vs-col>
        <vs-col vs-w="2"></vs-col>
        <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="1.5">
          <vs-button @click="viewFilter()" color="#0044BA">View Filter</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="1.5">
          <vs-button @click="setFilter()" color="#0044BA">Set Filter</vs-button>
        </vs-col>
      </vs-row>
    </vs-card>

    <vx-card class="roadmap-filter-card">
      <vs-row vs-w="12">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="desc-border-box no-padd">
              <p>
                Webinars<span>{{ count_webinars }}</span>
              </p>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="11.2" vs-type="flex" vs-justify="flex-start" class="no-padd">
          <vs-row class="mb-5 mt-6" vs-w="11.2">
            <vs-col vs-w="1.1" vs-offset="0.1" vs-type="flex" vs-justify="flex-start" vs-align="center">
              <h6 class="ele-side-head filter">Filters</h6>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop" id="team">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text" href.prevent @click="showTreeBox">
                  Team
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect :disabled="disabled" :multiple="true" v-model="init_selected_team" placeholder="Select..." :options="treeTeam" :value-consists-of="'LEAF_PRIORITY'" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop" id="city">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                  City
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity"
                    :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop" id="gm">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                  GM
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="initSelectedGM" :multiple="true" :options="treeDataGM"
                    :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop" id="spoc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                  SPOC
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect :multiple="true" v-model="initSelectedSpoc" :options="treeDataSpoc"
                    :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-offset="1.2" vs-w="2.5"
              class="tree-drop mt-12" id="level">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                  Level
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="selected_level" :options="treeLevels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5"
              class="tree-drop mt-12" id="lvl" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                  CM Level
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop mt-12"
              id="lvl" v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                  USP Level
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="initSelectedMaccLevels" :multiple="true" :value-consists-of="'LEAF_PRIORITY'"
                    :options="treeDataMaccLevels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop mt-12"
              id="eligible">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                  Course
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="initSelectedCourses" :multiple="true" :options="treeDataCourses"
                    :value-consists-of="'LEAF_PRIORITY'" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop mt-12"
              id="date">
              <datepicker placeholder="Registration Date" :minimumView="'month'" :maximumView="'month'"
                class="my_date_picker" :format="customFormatter" v-model="selected_date"></datepicker>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7" style="margin-top: 40px;">
              <div>
                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="11.2" vs-type="flex" vs-justify="flex-start" class="no-padd">

          <vs-row class="my-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="11.7">
              <vs-row class="criteria">
                <vs-row>
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="11.7">
                    <h6 class="criteria_heading">Search Criteria:</h6>
                  </vs-col>
                </vs-row>
                <vs-row vs-w="12">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                    <div style="display: block; width: 100%">
                      <span v-for="chip in selected_teams" :key="chip">
                        <vs-chip @click="removechip(chip, 'team')" class="criteria_chip" closable color="#EAE5FA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in cityData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'city')" color="#caecc5">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedCCData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip, 'cc')"
                          color="#D5FFF5">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedSpocData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'spoc')" color="#C3EDFA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>

                      <span v-if="selectedLevels != null">
                          <vs-chip class="criteria_chip" closable
                              @click="removechip(selectedLevels.toString(), 'Level')"
                              style="color:#000000; background: #f1ad78">
                              <b v-if="selectedLevels.toString() == 'u_level'">USP Level</b>
                              <b v-else>CM Level</b>
                          </vs-chip>
                      </span>

                      <span v-for="chip in selected_levels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'level')" color="#D5FFD9">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>

                      <span v-for="chip in selected_macc_levels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'macc')" color="#D5FFD9">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>

                      <span v-for="chip in selected_courses" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'course')" color="rgba(255, 194, 194, 0.69)">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                    <vs-button color="#0044ba" icon="search" @click="getWebinarStats()" size="small"></vs-button> &nbsp;
                    <vs-button color="#0044ba" icon="refresh" @click="refreshBdeLeadsData()" size="small"></vs-button>
                  </vs-col>
                </vs-row>
              </vs-row>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>

    <vs-card class="roadmap-filter-card webinar-stats" style="margin:2% 0 2% 0;padding-bottom:4%">
      <vs-row v-if="webinar_ids.length != 0" vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-6">
              <p style="text-align:center">
                Stats
              </p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <table class="rwd-table" id="stats">
                <thead>
                  <tr>
                    <th>City Classification</th>
                    <th>GM</th>
                    <th v-for="webinar_id in webinar_ids" :key="webinar_id">{{ webinar_headers[webinar_id] }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in webinar_stats_data" :key="index">
                    <!-- <td data-column="First Name">James</td> -->
                    <td>
                      {{ item.city }}
                    </td>

                    <td>
                      {{ item.gm }}
                    </td>
                    <td id="stats_total" v-for="webinar_id in webinar_ids" :key="webinar_id">
                      <div v-if="item[webinar_id] != null && item[webinar_id] != 0"
                        @click="checkGM(webinar_id, item); getSortedGM(item.gm);">{{ item[webinar_id] }}</div>
                      <div v-else>0</div>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td id="no-border"> </td>
                    <td id="no-border"> </td>
                    <!-- <td v-for="(webinar,key) in total_of_webinars" :key="key">{{ webinar }}</td> -->
                    <td id="stats_total" v-for="(webinar, key) in total_of_webinars" :key="key"
                      @click="getCandidateTotalDeatils(key)">{{ webinar }}</td>
                  </tr>
                </tbody>
              </table>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <div v-else class="no-data-found">
        No Data Found
      </div>
    </vs-card>

    <vx-card class="roadmap-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%" v-if="spoc_details.length != 0">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
              <p style="text-align:center">
                Levels
              </p>
            </vs-col>
            <vs-col vs-w="11" vs-offset="0.3" vs-type="flex" vs-justify="flex-start" class="no-padd mb-8">
              <p style="background: #f2f2f2;padding:4px 8px;border-radius: 5px;margin-right:4px"><b>Webinar</b> is
                {{ webinar_headers[sorted_webinar] }}</p>
              <p v-if="sorted_gm != ''" style="background: #f2f2f2;padding:4px 8px;border-radius: 5px;"><b>GM</b> is
                {{ sorted_gm }}</p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <div class="fixTableHead">
                <table class="rwd-table" id="levels-stats">
                  <thead>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM'">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in cm_levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'SR'">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in sr_levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && (selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'CM,SR,MAcc' || selected_teams.toString() == 'MAcc')">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels == null && selected_teams.length == 0">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.length == 0">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels == null && selected_teams.toString() == 'CM'">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in cm_levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels == null && selected_teams.toString() == 'SR'">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in sr_levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels == null && (selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'CM,SR,MAcc' || selected_teams.toString() == 'MAcc')">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="level in levels" :key="level">{{ level }}</th>
                    </tr>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
                      <th>GM</th>
                      <th>SPOC</th>
                      <th>Total</th>
                      <th v-for="ulevel in u_levels_array" :key="ulevel">{{ ulevel }}</th>
                    </tr>
                  </thead>
                  <tbody class="tbl-content" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in cm_levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'&& selected_teams.toString() == 'SR'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in sr_levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'CM,SR,MAcc' || selected_teams.toString() == 'MAcc'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels == null && selected_teams.length == 0">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level' && selected_teams.length == 0">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels == null && selected_teams.toString() == 'CM'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in cm_levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels == null && selected_teams.toString() == 'SR'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in sr_levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels == null && selected_teams.toString() == 'CM,SR' || selected_teams.toString() == 'CM,SR,MAcc' || selected_teams.toString() == 'MAcc'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="level in levels" :key="level">
                        <div v-if="item[level] != null && item[level] != 0"
                          @click="getTabulatorDeatilsPage(item, level); getSortedLevels(item, level);">
                          {{
                            item[level] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="tbl-content" v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
                    <tr v-for="item in spoc_details" :key="item.spoc_id">
                      <!-- <td data-column="First Name">James</td> -->
                      <td>
                        {{ item.gm_name }}
                      </td>

                      <td>
                        {{ item.spoc_name }}
                      </td>
                      <td id="stats_total" @click="getTabulatorDeatilsPage(item, 'all'); getSortedLevels(item, 'spoc')">
                        {{ item.total }}
                      </td>
                      <td id="stats_total" v-for="ulevel in u_levels_array" :key="ulevel">
                        <div v-if="item[ulevel] != null && item[ulevel] != 0"
                          @click="getTabulatorDeatilsPage(item, ulevel); getSortedLevels(item, ulevel);">
                          {{
                            item[ulevel] }}</div>
                        <div v-else>0</div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'">
                      <td id="no-border"> </td>
                      <td id="no-border"> </td>
                      <td id="stats_total" v-for="(level, key) in total_of_levels" :key="key"
                        @click="getTabulatorTotalDetails(key); getSortedLevels(key, 'total');">{{ level }}</td>
                    </tr>
                    <tr v-if="selectedLevels == null && selected_teams.length == 0">
                      <td id="no-border"> </td>
                      <td id="no-border"> </td>
                      <td id="stats_total" v-for="(level, key) in total_of_levels" :key="key"
                        @click="getTabulatorTotalDetails(key); getSortedLevels(key, 'total');">{{ level }}</td>
                    </tr>
                    <tr v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'">
                      <td id="no-border"> </td>
                      <td id="no-border"> </td>
                      <td id="stats_total" v-for="(level, key) in total_of_levels" :key="key"
                        @click="getTabulatorTotalDetails(key); getSortedLevels(key, 'total');">{{ level }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <!-- <div v-else class="no-data-found">
        No Data Found
      </div> -->
    </vx-card>

    <vs-card style="margin-top: 1%" v-if="roadmap_webinars.length != 0">
      <roadmap-webinar-tabulator :roadmap_webinars="roadmap_webinars" :roadmap_props="roadmap_props"
        :webinar_headers="webinar_headers"></roadmap-webinar-tabulator>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-pagination :total="total_pages" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>
  
<script>
import moment from "moment";
import axios from "axios";
import vSelect from "vue-select";
import constants from "../../../constants.json";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Datepicker from "vuejs-datepicker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "vue-select/dist/vue-select.css";
import RoadmapWebinarTabulator from './RoadmapWebinarTabulator.vue';
export default {
  components: {
    Treeselect,
    "v-select": vSelect,
    Datepicker,
    RoadmapWebinarTabulator
  },
  data: () => ({
    selected_webinar_id: "",
    prv_webinars: [],
    roadmap_props: {},
    complete_webinar: false,
    sorted_webinar: '',
    sorted_gm: '',
    sorted_spoc: '',
    sorted_lvl: '',
    webinar_headers: [],
    selected_levels: [],
    cityData: [],
    user_with_gms: [],
    selectedCCData: [],
    selectedSpocData: [],
    logged_in_spoc_id: 39,
    webinars_list: [],
    selected_webinar: [],
    spoc_details: [],
    cm_levels: ['M1', 'M2', 'M3', 'M3+','M3++', 'M4-', 'M4', 'M5', 'M6', 'others'],
    sr_levels: ['M7','M7-','M7+','M7#','M8','M8+','M8-','M9','M9-','M10','M7-JJ','M7X','M7D1','M7D2','M7-IR','M7-INT','others'],
    levels: ['M1', 'M2', 'M3', 'M3+', 'M3++', 'M4-', 'M4', 'M5', 'M6', 'M7','M7-','M7+','M7#','M8','M8+','M8-','M9','M9-','M10','M7-JJ','M7X','M7D1','M7D2','M7-IR','M7-INT','others'],
    u_levels_array: ['U0--','U0','U1','U2','U3-','U3','U3+','U3++','U4-','U4','U5','U5+','U6-','U6','U6+','U7-','U7','U8','U9','U10','U4R','others'],
    spoc_data: [],
    cities: [],
    initSelectedCity: [],
    initSelectedGM: [],
    initSelectedSpoc: [],
    initSelectedLevels: [],
    treeDataCity: [
      {
        id: "All",
        label: "All",
        children: []
      }
    ],
    treeDataGM: [
      {
        id: "All",
        label: "All",
        children: []
      }
    ],
    treeDataSpoc: [
      {
        id: "All",
        label: "All",
        children: []
      }
    ],
    selected_level: null,
    selectedLevels: null,
    treeLevels: [
          { id: "m_level", label: "CM Level" },
          { id: "u_level", label: "USP Level" }
      ],
    treeDataLevels: [
      {
        id: "All",
        label: "All",
        children: [
          {
            id: "P1",
            label: "P1",
            children: [
              {
                id: "M3",
                label: "M3",
              },
              {
                id: "M3+",
                label: "M3+",
              },
              {
                id: "M3++",
                label: "M3++",
              },
              {
                id: "M5",
                label: "M5",
              },
              {
                id: "M6",
                label: "M6",
              },
            ],
          },
          {
            id: "P2",
            label: "P2",
            children: [
              {
                id: "M4",
                label: "M4",
              },
              {
                id: "M2",
                label: "M2",
              },
            ],
          },
          {
            id: "P3",
            label: "P3",
            children: [
              {
                id: "M4-",
                label: "M4-",
              },
              {
                id: "M1",
                label: "M1",
              },
            ],
          },
          {
            id: "ENROLLED",
            label: "ENROLLED",
            children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ]
          },
          {
            id: "Dnd",
            label: "Dnd",
            children: [
              {
                id: "N/A",
                label: "N/A",
              },
              {
                id: "DND",
                label: "DND",
              },
            ]
          }
        ],
      },
    ],
    treeDataMaccLevels: [
      {
        id: "All",
        label: "All",
        children: [
          { id: "blank", label: "No U Level"},
          { id: "U0--", label: "U0--" },
          { id: "U0", label: "U0" },
          { id: "U1", label: "U1" },
          { id: "U1+", label: "U1+" },
          { id: "U2", label: "U2" },
          { id: "U3-", label: "U3-" },
          { id: "U3", label: "U3" },
          { id: "U3+", label: "U3+" },
          { id: "U3++", label: "U3++"},
          { id: "U4", label: "U4" },
          { id: "U5", label: "U5" },
          { id: "U6", label: "U6" },
          { id: "U7-", label: "U7-" },
          { id: "U7", label: "U7" },
          { id: "U7R", label: "U7R" },
          { id: "U7+", label: "U7+" },
          { id: "U8", label: "U8" },
          { id: "U8+", label: "U8+" },
          { id: "U9-", label: "U9-" },
          { id: "U9", label: "U9" },
          { id: "U9+", label: "U9+" },
          { id: "U10-", label: "U10-" },
          { id: "U10", label: "U10" },
          { id: "U11", label: "U11" },
          { id: "U11+", label: "U11+" },
          { id: "U12", label: "U12" },
        ]
      }
    ],
    initSelectedCourses: [],
    selected_courses: [],
    treeDataCourses: [
      {
        id: "All",
        label: "All",
        children: [
          {
            id: "CPA",
            label: "CPA",
          },
          {
            id: "CPA-AA",
            label: "CPA-AA",
          },
          {
            id: "AICPA",
            label: "AICPA",
          },
          // {
          //   id: "CISA",
          //   label: "CISA",
          // },
          // {
          //   id: "EA",
          //   label: "EA",
          // },
          // {
          //   id: "CIA",
          //   label: "CIA",
          // },
          {
            id: "CMA",
            label: "CMA",
          },
          {
            id: "CFA",
            label: "CFA",
          },
          {
            id: "CFA-L1",
            label: "CFA-L1",
          },
          {
            id: "CFA-L2",
            label: "CFA-L2",
          },
          {
            id: "CFA-L3",
            label: "CFA-L3",
          },
          {
            id: "FRM",
            label: "FRM",
          },
          {
            id: "FRM-P1",
            label: "FRM-P1",
          },
          {
            id: "FRM-P2",
            label: "FRM-P2",
          },
          // {
          //   id: "IIMI-BA",
          //   label: "IIMI-BA",
          // },
          // {
          //   id: "XLRI-HR",
          //   label: "XLRI-HR",
          // },
          // {
          //   id: "XLRI-SH",
          //   label: "XLRI-SH",
          // },
          // {
          //   id: "RPO",
          //   label: "RPO",
          // },
          // {
          //   id: "IIML-FT",
          //   label: "IIML-FT",
          // },
          {
            id: "USP",
            label: "USP",
          },
        ],
      },
    ],
    selected_date: null,
    roadmap_webinars: [],
    filter_spoc_ids: [],
    webinar_ids: [],
    webinar_stats_data: [],
    webinar_stats_response: [],
    total_of_levels: {},
    total_of_webinars: {},
    currentTablePage: 1,
    total_pages: 0,
    clicked_row: {},
    count_webinars: 0,
    clicked_city: '',
    init_selected_team: [],
    selected_teams: [],
    treeTeam: [
      {
        id: "All",
        label: "All",
        children: [
          { id: "CM", label: "CM" },
          { id: "SR", label: "SR" },
          { id: "MAcc", label: "MAcc" },
        ]
      }
    ],
    initSelectedMaccLevels: [],
    selected_macc_levels: [],
    loggedin_team: "",
    gm_ids: [],
    default_selected_city: [],
    default_selected_gm:[],
    default_selected_spoc:[],
    disabled: false,
    logged_team:""
  }),

  mounted() {
    this.logged_team =  localStorage.getItem('team')
    this.logged_in_spoc_id = localStorage.getItem("spoc_id");
    this.fetchWebinars();
    this.getF2fRegistrant();
    this.getUsersWithGMs();
    this.userWiseTeam();
    this.selected_level = 'm_level';
  },
  watch: {
    selected_level: function () {
        this.initSelectedLevels = [];
        this.initSelectedMaccLevels = [];
        this.mergeAllSearch()
        // this.getUsersWithGMs();
    },
    initSelectedCity: function (val) {
      if (val.length != 0) {
        this.getSpocIds()
        // this.getDropdownData()
        // console.log(val);
        this.mergeAllSearch();
      }
      else {
        let team = localStorage.getItem('team')
        if (team == 'CM' || team == 'SR' || team == 'GM') {
          this.initSelectedCity = this.default_selected_city
        }
      }
      if(val.length == 0){
        this.cityData = [];
      }
    },
    init_selected_team: function (val) {
      console.log('object', val);
      this.mergeAllSearch();
      // this.getUsersWithGMs();
    },
    selected_webinar: function (val) {
      if (val.length > 6) {
        this.selected_webinar = this.prv_webinars
      } else {
        this.prv_webinars = this.selected_webinar
      }
    },
    initSelectedGM: function (val) {
      // console.log(val);
      // this.mergeAllSearch();
      if(val.length != 0){
        this.getSpocIds()
        this.mergeAllSearch();
      }else{
        let team = localStorage.getItem('team')
        if(team == 'CM' || team == 'SR' || team == 'GM'){
          this.initSelectedGM = this.default_selected_gm
        }
      }
      if(val.length == 0){
         this.selectedGMData = [];
      }
    },
    initSelectedSpoc: function (val) {
      // console.log(val);
      // this.mergeAllSearch();
      if(val.length != 0){
        this.getSpocIds()
        this.mergeAllSearch();
      }else{
        let team = localStorage.getItem('team')
        if(team == 'CM' || team == 'SR' || team == 'GM'){
          this.initSelectedSpoc = this.default_selected_spoc
        }
      }
      if(val.length == 0){
          this.selectedSpocData = [];
      }
    },
    initSelectedMaccLevels: function (val) {
      console.log(val);
      this.mergeAllSearch()
    },
    initSelectedLevels: function (val) {
      console.log(val);
      this.mergeAllSearch();
    },
    initSelectedCourses(val) {
      console.log("initSelectedCourses", val);
      this.mergeAllSearch();
    },
    currentTablePage(val) {
      console.log("initSelectedCourses", val);
      this.getTabulatorDeatils(this.clicked_row.row, this.clicked_row.level)
    }
  },
  methods: {
    checkGM(webinar_id, item){
      this.clicked_city = item.city;
      this.spoc_details = [];
      this.roadmap_webinars = [];
      this.selected_webinar_id = webinar_id;
      if(item.id != 'no_gm'){
        this.getCandidateDeatils(webinar_id, item);
      }
      if(item.id == 'no_gm'){
        this.getTabulatorDeatilsPage(item, 'total');
        this.getSortedLevels(item, 'total');
      }
    },
    userWiseTeam(){
      this.init_selected_team = [];
      this.loggedin_team = localStorage.getItem('team');
      if(this.loggedin_team == 'Admin'){
          this.disabled = false;
      } else {
          this.disabled = true;
      }
      if (this.loggedin_team === 'Admin') {
          this.init_selected_team.push('CM');
      }
      if (this.loggedin_team === 'CM') {
          this.init_selected_team.push('CM');
      } else if (this.loggedin_team === 'SR') {
          this.init_selected_team.push('SR');
      } else if (this.loggedin_team === 'ExEd') {
          this.init_selected_team.push('ExEd');
      } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'CM') {
          this.init_selected_team.push('CM');
      }
      else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") != 'CM') {
          this.init_selected_team.push('SR');
      }
    },
    getTabulatorDeatilsPage(row, level) {
      this.currentTablePage = 1
      this.getTabulatorDeatils(row, level)
    },
    getTabulatorDeatils(row, level) {
      this.clicked_row['row'] = row
      this.clicked_row['level'] = level
      let obj = {
        // 'webinar_id': row.webinar_id,
        // 'gm_id': row.gm_id,
        'spoc_id': row.spoc_id,
        // 'm_levels': level,
        'courses': this.selected_courses.toString(),
        'city': this.clicked_city,
        'selected_level': this.selected_level,
        'selected_team': this.selected_teams.toString(),

      }
      if(this.selected_level == 'm_level'){
        obj['m_levels'] = level
      }
      if(this.selected_level == 'u_level'){
        obj['u_levels'] = level
      }
      if('id' in row){
        obj.gm_id = row.id;
      }
      if('gm_id' in row){
        obj.gm_id = row.gm_id;
      }
      if('webinar_id' in row){
        obj.webinar_id = row.webinar_id
      } else{
        obj.webinar_id = this.selected_webinar_id
      }
      if (this.selected_date != null) {
        obj['month'] = moment(this.selected_date).format("MM");
        obj['year'] = moment(this.selected_date).format("YYYY")
      }
      if (this.initSelectedMaccLevels.length != 0) {
        obj['u_levels'] = this.initSelectedMaccLevels.toString()
      }
      this.$vs.loading({ type: 'material' });
      let url = `${constants.MILES_WEBINARS}viewCandidateDetails?page=${this.currentTablePage}`;
      axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` } })
        .then((response) => {
          this.roadmap_webinars = response.data.candidate_details.data
          let obj = {
            'sorted_webinar': this.sorted_webinar,
            'sorted_gm': this.sorted_gm,
            'sorted_spoc': this.sorted_spoc,
            'sorted_lvl': this.sorted_lvl
          }
          this.roadmap_props = obj
          this.total_pages = response.data.candidate_details.last_page
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error)
          this.$vs.loading.close();
        });
    },
    getTabulatorTotalDetails(level) {
      this.currentTablePage = 1
      let obj = {
        webinar_id: '',
        gm_id: [],
        spoc_id: [],
      }
      this.spoc_details.forEach(element => {
        obj.webinar_id = element.webinar_id
        obj.gm_id.push(element.gm_id)
        obj.spoc_id.push(element.spoc_id)
      });
      obj.spoc_id = obj.spoc_id.toString()
      obj.gm_id = obj.gm_id.toString()
      if (level == 'total') {
        level = 'all'
      }
      this.getTabulatorDeatils(obj, level)
    },
    getUsersWithGMs() {
      let url = `${constants.SERVER_API}getUsersWithGMs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          let logged_in_user = localStorage.getItem('spoc_id')
            let team = localStorage.getItem('team')
            if (team == 'CM' || team == 'SR' || team == 'ExEd') {
                response.data.forEach(ele => {
                if (logged_in_user == ele.id) {
                    this.user_with_gms = []
                    this.user_with_gms.push(ele);
                    this.getDropdownData();
                }
                });
            }
          else if (localStorage.getItem('team') == 'GM') {
            this.getGmReportingSpocs(response.data)
          } else {
          this.user_with_gms = []
          let team = this.init_selected_team.toString()
          let i = this.init_selected_team.indexOf('MAcc');
          this.init_selected_team[i] = 'ExEd';
          if (team == 'MAcc') {
            team = 'ExEd'
          }
          response.data.forEach(element => {
            if (team != 'All' && this.init_selected_team.length == 1 && element['team'] == team) {
              this.user_with_gms.push(element)
            } else if (this.init_selected_team.length == 2 && this.init_selected_team.includes(element['team'])) {
              this.user_with_gms.push(element)
            } else if (team == 'All' || this.init_selected_team.length == 0) {
              if (element['team'] == 'CM' || element['team'] == 'ExEd' || element['team'] == 'SR') {
                this.user_with_gms.push(element)
              }
            }
          });
          // this.user_with_gms = response.data;
          this.getDropdownData();
        }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGmReportingSpocs(res) {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            ids.push(element.id);
          }
          this.gm_ids = ids
          this.user_with_gms = []
          this.gm_ids.forEach(gm => {
            res.forEach(ele => {
              if (ele.id == gm) {
                this.user_with_gms.push(ele);
              }
            });
          });
          this.getDropdownData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getDropdownData() {
        this.initSelectedCity = [];
        this.initSelectedGM = [];
        this.initSelectedSpoc = [];
        this.treeDataCity = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ]
        let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            if(city_options.includes(element)){
              this.treeDataCity[0].children.push(obj);
            }
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataCity[0].children.forEach((city) => {
            if (element.city === city.label) {
                let cc_set = new Set(city.children.map((item) => item.label));
                // if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                //   if(this.logged_in_spoc_id == 132 ||this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
                //     let cc_obj = {
                //       id: element.city + "_" + element.city_classification,
                //       label: element.city_classification
                //     }
                //     city.children.push(cc_obj);
                //      if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                //     this.initSelectedCity.push(cc_obj)
                //      }
                //     this.default_selected_city = this.initSelectedCity;
                //    }
                //   else{
                //     if(element.city_classification != 'X'){
                //       let cc_obj = {
                //       id: element.city + "_" + element.city_classification,
                //       label: element.city_classification
                //     }
                //     city.children.push(cc_obj);
                //      if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                //       this.initSelectedCity.push(cc_obj)
                //     }
                //     this.default_selected_city = this.initSelectedCity;
                //     } 
                //    }
                // // let team = localStorage.getItem('team')
               
                // }
                if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                  if(this.logged_in_spoc_id == 132 ||this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
                    let cc_obj = {
                      id: element.city + "_" + element.city_classification,
                      label: element.city_classification
                    }
                    city.children.push(cc_obj);
                    if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                      this.initSelectedCity.push(cc_obj)
                    }
                    this.default_selected_city = this.initSelectedCity;
                  }
                  else{
                    if(element.city_classification != 'X'){
                      let cc_obj = 
                      {
                        id: element.city + "_" + element.city_classification,
                        label: element.city_classification
                      }
                      city.children.push(cc_obj);
                       if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                        this.initSelectedCity.push(cc_obj)
                      }
                      this.default_selected_city = this.initSelectedCity;
                      } 
                   }
                
               
                }
            }
            })
        });
        this.treeDataGM = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ],
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            this.treeDataGM[0].children.push(obj);
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataGM[0].children.forEach((city) => {
            if (element.city === city.label) {
                if (element.gm !== "No GM") {
                let gm_set = new Set(city.children.map((item) => item.label));
               
                // if (!gm_set.has(element.gm)) {
                //     let gm_obj = {
                //     id: element.city + "_" + element.gm,
                //     label: element.gm
                //     }
                //     city.children.push(gm_obj);
                //     let team = localStorage.getItem('team')
                //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                //     this.initSelectedGM.push(gm_obj)
                //     }
                //     this.default_selected_gm = this.initSelectedGM
                // }
                if (!gm_set.has(element.gm)) {
                  let gm_data = element.gm
                  if(this.logged_in_spoc_id == 132 ||this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
                    let gm_obj = {
                      id: element.city + "_" + element.gm,
                      label: element.gm
                    }
                    city.children.push(gm_obj);
                    if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                      this.initSelectedGM.push(gm_obj)
                    }
                    this.default_selected_gm = this.initSelectedGM;
                  }
                  else{
                    if(gm_data.substring(0,1) != 'X'){
                      let gm_obj = {
                        id:element.city + "_" + element.gm,
                        label: element.gm
                      }
                    city.children.push(gm_obj);
                    if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                      this.initSelectedGM.push(gm_obj)
                    }
                    this.default_selected_gm = this.initSelectedGM;
                    }
                  }
                }
               
                }
              }
            })
        });
        this.treeDataSpoc = [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ],
        uniqe_city.forEach(element => {
            let obj = {
            id: element,
            label: element,
            children: []
            }
            this.treeDataSpoc[0].children.push(obj);
        })
        this.user_with_gms.forEach((element) => {
            this.treeDataSpoc[0].children.forEach((city) => {
            if (element.city === city.label) {
                let gm_set = new Set(city.children.map((item) => item.label));
                // if (!gm_set.has(element.last_name)) {
                // let spoc_obj = {
                //     id: element.city + "_" + element.first_name + " " + element.last_name,
                //     label: element.first_name + " " + element.last_name
                // }
                // city.children.push(spoc_obj);
                // let team = localStorage.getItem('team')
                //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                //     this.initSelectedSpoc.push(spoc_obj)
                //     }
                //     this.default_selected_spoc = this.initSelectedSpoc
                // }
                if (!gm_set.has(element.last_name)) {
                        let name = element.first_name
                        if(this.logged_in_spoc_id == 132 ||this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
                            let spoc_obj = {
                            id: element.city + "_" + element.first_name + " " + element.last_name,
                            label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                        }else{
                        if(name.substring(0,1) != 'X'){
                            let spoc_obj = {
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (this.logged_team == 'CM' || this.logged_team == 'SR' || this.logged_team == 'GM' || this.logged_team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                        }
                        }
                    }


            }
            })
        });
        let team = localStorage.getItem('team')
        if (team == 'GM' || team == 'Admin') {
        this.getSpocIds(this.getWebinarStats)
        }
        },
    showTreeBox() {
      return this.treeDataBDE;
    },
    getSpocIds(callback = () => { }) {
    // getSpocIds() {
      let city = [];
      let cc = [];
      let uniqe_city = [];
      let uniqe_gm = [];
      let uniqe_spoc = [];
      if (this.initSelectedCity.length != 0) {
        this.initSelectedCity.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(CC => {
              cc.push(CC.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            cc.push(item.label)
          }
        });

        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];

      }
      if (this.initSelectedGM.length != 0) {
        this.initSelectedGM.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_gm.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_gm.push(item.label)
          }
        });

        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if (this.initSelectedSpoc.length != 0) {
        this.initSelectedSpoc.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_spoc.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_spoc.push(item.label)
          }
        });

        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      let spoc_ids = [];
      this.user_with_gms.forEach(raw => {
        if (localStorage.getItem('team') == 'GM') {
          if (this.initSelectedSpoc.length != 0) {
          uniqe_city.forEach(city => {
            uniqe_spoc.forEach(spoc => {
              if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        }else{
        if (this.initSelectedCity.length != 0) {
          uniqe_city.forEach(city => {
            cc.forEach(cc => {
              if (raw.city == city && raw.city_classification == cc) {
                spoc_ids.push(raw.id);
              }
            })
          })
        } if (this.initSelectedGM.length != 0) {
          uniqe_city.forEach(city => {
            uniqe_gm.forEach(gm => {
              if (raw.city == city && raw.gm == gm) {
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        // uniqe_city.length != 0 && uniqe_gm.length != 0 &&
        if (this.initSelectedSpoc.length != 0) {
          uniqe_city.forEach(city => {
            uniqe_spoc.forEach(spoc => {
              if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                spoc_ids.push(raw.id);
              }
            })
          })
        }
      }
        
        
      });
      if (this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0 && this.init_selected_team != 0) {
        this.treeDataSpoc[0].children.forEach(child => {
          child.children.forEach(spoc => {
            this.user_with_gms.forEach(raw => {
              if (raw.first_name + " " + raw.last_name == spoc.label) {
                spoc_ids.push(raw.id);
              }
            });
          });
        });
      }

      spoc_ids = [...new Set(spoc_ids.map((data) => data))];
      console.log("spoc Ids", spoc_ids.join())
      this.filter_spoc_ids = spoc_ids.join()
      let team = localStorage.getItem('team')
      if (team == 'GM' || team == 'Admin') {
      return callback();
      }else{
       this.getWebinarStats();
      }
    },
    processGMData(data) {
      this.selected_webinar = [];
      data.forEach((w) => {
        if (!this.selected_webinar.includes(w.webinar_id)) {
          this.selected_webinar.push(w.webinar_id);
        }
      });
      this.webinar_headers = {}
      this.webinars_list.forEach(webinar => {
        this.webinar_headers[webinar.id] = webinar.label
      });
      this.getSpocIds()
    },
    getSortedGM(gm) {
      this.sorted_gm = gm
      this.complete_webinar = false
    },
    getSortedLevels(item, lvl) {
      if (item == 'total') {
        this.getSortedGMName('all')
        this.sorted_spoc = 'all'
        this.sorted_lvl = 'all'
      } else if (item != 'total' && lvl == 'total') {
        this.getSortedGMName('all')
        this.sorted_spoc = 'all'
        this.sorted_lvl = item
      } else if (lvl == 'spoc') {
        this.getSortedGMName(item.gm_name)
        this.sorted_spoc = item.spoc_name
        this.sorted_lvl = 'all'
      } else {
        this.sorted_gm = item.gm_name
        this.sorted_spoc = item.spoc_name
        this.sorted_lvl = lvl
      }
    },
    clearDateFilter() {
      this.selected_date = null
    },
    getSortedGMName(item) {
      if (this.complete_webinar) {
        this.sorted_gm = item
      }
    },
    getCandidateDeatils(id, item) {
      this.$vs.loading();
      this.sorted_spoc = ''
      this.sorted_lvl = ''
      let webinars = []
      this.clicked_city = item.city
      let obj = {
        'selected_team': this.selected_teams.toString(),
        'webinar_id': id,
        'spoc_ids': this.filter_spoc_ids,
        'courses': this.selected_courses.toString(),
        'm_levels': this.selected_levels.toString(),
        'gm_ids': item.id,
        'city': item.city,
        'selected_level': this.selected_level 

      }
      if (this.selected_date != null) {
        obj['month'] = moment(this.selected_date).format("MM");
        obj['year'] = moment(this.selected_date).format("YYYY")
      }
      if (this.initSelectedMaccLevels.length != 0) {
        obj['u_levels'] = this.initSelectedMaccLevels.toString()
      }

      let url = `${constants.MILES_WEBINARS}levelWiseRoadmapWBCounts`;
      axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` } })
        .then((response) => {
          webinars = response.data.webinar_stats
          this.spoc_details = []
          this.roadmap_webinars = []
          let duplicates = []
          webinars.forEach(webinar => {
            if (id == webinar.webinar_id) {
              webinar.spoc_data.forEach(spoc => {
                this.sorted_webinar = id
                spoc.level_counts.forEach(level => {
                  let data = duplicates.find(o => o[webinar.gm_name] === spoc.spoc_name);
                  if (data == undefined) {
                    let dup = {}
                    dup[webinar.gm_name] = spoc.spoc_name
                    duplicates.push(dup)
                    let obj =
                    {
                      spoc_id: spoc.spoc_id,
                      spoc_name: spoc.spoc_name,
                      'gm_id': webinar.gm_id,
                      gm_name: webinar.gm_name,
                      webinar_id: id,
                      total: spoc.spoc_webinar_total_count
                    }
                    obj[level.level] = level.level_count
                    this.spoc_details.push(obj)
                  } else {
                    this.spoc_details.forEach(ele => {
                      if (ele.spoc_name == spoc.spoc_name) {
                        ele[level.level] = level.level_count
                      }
                    });
                  }
                });
              });
            }
          });
          this.getTotalOfLevels(this.spoc_details)
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error)
          this.$vs.loading.close();
        });
    },
    getCandidateTotalDeatils(id) {
      this.sorted_gm = ''
      this.complete_webinar = true
      let gm_ids = []
      this.webinar_stats_response.forEach(webinar => {
        gm_ids.push(webinar.gm_id)
      });
      gm_ids = [...new Set(gm_ids)];
      this.getCandidateDeatils(id, gm_ids.toString())
    },
    spocForMultipleGms(gms, ele) {
      let status = true
      for (let i = 0; i < gms.length; i++) {
        const gm = gms[i];
        if (ele.gm == gm.gm && ele.spoc == gm.spoc) {
          status = false
        } else { status = true }
      }
      return status
    },
    getTotalOfLevels(data) {
      this.total_of_levels = {}
      data.forEach(element => {
        if (!this.total_of_levels.hasOwnProperty('total') && element['total'] != undefined) {
          this.total_of_levels['total'] = element['total']
        } else if (element['total'] != undefined) {
          this.total_of_levels['total'] += element['total']
        }
        if(this.selectedLevels == null || this.selected_teams.length == 0){
          this.levels.forEach(level => {
            if (!this.total_of_levels.hasOwnProperty(level) && element[level] != undefined) {
              this.total_of_levels[level] = element[level]
            } else if (element[level] != undefined) {
              this.total_of_levels[level] += element[level] 
            }
          });
        }
        if(this.selectedLevels != null && this.selectedLevels.toString() == 'm_level'){
          this.levels.forEach(level => {
            if (!this.total_of_levels.hasOwnProperty(level) && element[level] != undefined) {
              this.total_of_levels[level] = element[level]
            } else if (element[level] != undefined) {
              this.total_of_levels[level] += element[level]
            }
          });
        }
        if(this.selectedLevels != null && this.selectedLevels.toString() == 'u_level'){
          this.u_levels_array.forEach(level => {
            if (!this.total_of_levels.hasOwnProperty(level) && element[level] != undefined) {
              this.total_of_levels[level] = element[level]
            } else if (element[level] != undefined) {
              this.total_of_levels[level] += element[level]
            }
          });
        }
      });
    },
    getTotalOfWebinars(data) {
      this.total_of_webinars = {}
      data.forEach(element => {
        this.webinar_ids.forEach(webinar => {
          if (this.total_of_webinars.hasOwnProperty(webinar) && element[webinar] != undefined) {
            this.total_of_webinars[webinar] += element[webinar]
          } else if (element[webinar] != undefined) {
            this.total_of_webinars[webinar] = element[webinar]
          }
        });
      });
      this.count_webinars = Object.values(this.total_of_webinars).reduce((a, b) => a + b, 0);
    },
    getWebinarStats() {
      this.sorted_gm = ''
      this.spoc_details = []
      this.roadmap_webinars = []
      // this.$vs.loading({ type: 'material' });
      let obj = {
        webinar_ids: this.selected_webinar.toString(),
        spoc_ids: this.filter_spoc_ids,
        courses: this.selected_courses.toString(),
        m_levels: this.selected_levels.toString(),
      }
      if (this.selected_date != null) {
        obj['month'] = moment(this.selected_date).format("MM");
        obj['year'] = moment(this.selected_date).format("YYYY")
      }
      if (this.initSelectedMaccLevels.length != 0) {
        obj['u_levels'] = this.initSelectedMaccLevels.toString()
      }
      let url = `${constants.MILES_WEBINARS}roadmapWebinarGMStats`;
      axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` } })
        .then((response) => {
          console.log("roadmapWebinarGMStatTest",response.data);
          let webinar_ids = []
          let gms = []
          let duplicates = []
          this.webinar_stats_response = response.data.webinar_stats
          response.data.webinar_stats.forEach(webinar => {
            let cc = webinar.city_classification
            webinar_ids.push(webinar.webinar_id)
            let data = duplicates.find(o => o[webinar.gm_name] === cc);
            if (data == undefined && cc != 'T' && cc != 'X') {
              let dup = {}
              if(cc != 'No city'){
                dup[webinar.gm_name] = cc
              }
              duplicates.push(dup) 
              let obj = {
                city: cc,
                gm: webinar.gm_name,
                id: webinar.gm_id,
              }
              obj[webinar.webinar_id] = webinar.count
              gms.push(obj)
            } else {
              gms.forEach(gm => {
                if (gm.gm == webinar.gm_name && gm.city == cc) {
                  gm[webinar.webinar_id] = webinar.count
                }
              });
            }
          });
          this.$vs.loading.close();
          gms.sort((a, b) => a.city.localeCompare(b.city));
          this.webinar_stats_data = gms
          this.webinar_ids = [...new Set(webinar_ids)]
          this.getTotalOfWebinars(gms)
        })
        .catch((error) => {
          this.handleError(error)
          this.$vs.loading.close();
        });
    },
    setFilter() {
      console.log(this.selected_webinar);
      let url = `${constants.MILES_WEBINARS}setFilter`;
      let obj = {
        webinar_ids: this.selected_webinar.join()
      };
      axios
        .put(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$vs.notify({
              text: "Webinars List Updated Successfully",
              color: "success",
            });
          }
          this.getF2fRegistrant();
          this.getWebinarStats();
        });
    },
    viewFilter() {
      console.log(this.selected_webinar);
      let url = `${constants.MILES_WEBINARS}viewFilter`;
      let obj = {
        webinar_ids: this.selected_webinar.join()
      };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
            this.processGMData(response.data.webinar_stats);
          }
          if (response.data.status == "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getWebinarStats();
        });
    },
    fetchWebinars() {
      let url = `${constants.MILES_WEBINARS}getAllRoadmapWebinars`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then((response) => {
          const webinars = [];
          response.data.forEach((w) => {
            let webinar = w;
            webinar.label = `${moment
              .unix(webinar.start_time_unix)
              .format("DD-MMM-YYYY")} : ${w.topic}`;
            webinar.id = w.webinar_id;
            webinars.push(webinar);
          });
          this.webinars_list = webinars;
        });
    },
    getF2fRegistrant() {
      let url = `${constants.MILES_WEBINARS}roadmapWebinarStats`;
      axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          this.processGMData(response.data);
        })
        .catch((error) => {
          this.handleError(error)
        });
    },
    refreshBdeLeadsData() {
      this.selected_level = 'm_level';
      this.init_selected_team = [];
      this.selected_teams = [];
      this.clicked_city = '';
      this.sorted_webinar = '';
      this.sorted_gm = '';
      this.sorted_spoc = '';
      this.sorted_lvl = '';
      this.clicked_row = {};
      this.selected_date = null;
      this.initSelectedMaccLevels = [];
      this.selected_macc_levels = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      this.initSelectedSpoc = [];
      this.initSelectedGM = [];
      this.initSelectedCity = [];
      this.selectedCityData = [];
      this.selectedCCData = [];
      this.selectedSpocData = [];
      this.selected_courses = [];
      this.selected_levels = [];
      this.spoc_details = [];
      this.roadmap_webinars = [];
      this.getSpocIds();
      this.userWiseTeam();
      this.getUsersWithGMs();
    },
    mergeAllSearch() {
      this.selectedLevels = null;
      this.selectedLevels = this.selected_level
      this.selected_teams = []
      this.init_selected_team.forEach((sort) => {
        if (sort === "All") {
          this.treeTeam[0].children.forEach((child) => {
            this.selected_teams.push(child.label);
          });
        } else {
          this.selected_teams.push(sort);
        }
      });

      this.cityData = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            this.cityData.push(child.label);
          });
        } else {
          this.cityData.push(sort.label);
        }
      });

      this.selectedCCData = [];
      this.initSelectedGM.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataGM[0].children.forEach((child) => {
            if (!this.selectedCCData.includes(child.label)) {
              this.selectedCCData.push(child.label);
            }
          });
        } else {
          if (!this.selectedCCData.includes(sort.label)) {
            this.selectedCCData.push(sort.label);
          }
        }
      });

      this.selectedSpocData = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            if (!this.selectedSpocData.includes(child.label)) {
              this.selectedSpocData.push(child.label);
            }
          });
        } else {
          if (!this.selectedSpocData.includes(sort.label)) {
            this.selectedSpocData.push(sort.label);
          }
        }
      });

      this.selected_macc_levels = []
      this.initSelectedMaccLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataMaccLevels[0].children.forEach((child) => {
            this.selected_macc_levels.push(child.label);
          });
        } else {
          this.selected_macc_levels.push(sort);
        }
      });

      this.selected_courses = [];
      this.initSelectedCourses.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.selected_courses.push(child.label);
          });
        } else {
          this.selected_courses.push(sort);
        }
      });
      this.selected_levels = [];
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        }
        else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else {
          this.selected_levels.push(sort);
        }
      });
    },
    removechip(chip, name) {
      if (name == "team") {
        this.selected_teams = [];
        if (this.init_selected_team.indexOf(chip) === -1) {
          this.init_selected_team = [];
          this.treeTeam[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.init_selected_team.push(child.label);
              this.selected_teams.push(child.label);
            }
          });
        } else {
          this.init_selected_team.splice(this.init_selected_team.indexOf(chip), 1);
          this.selected_teams.splice(this.selected_teams.indexOf(chip), 1);
        }
      }
      if (name == 'Level') {
        this.selected_level = null,
        this.selectedLevels = null
      }
      if (name == "city") {
        this.cityData.splice(this.cityData.indexOf(chip), 1);
        let index = this.initSelectedCity.findIndex(x => x.label === chip);
        this.initSelectedCity.splice(index, 1);
      }

      if (name == "cc") {
        this.selectedCCData.splice(this.selectedCCData.indexOf(chip), 1);
        let index = this.initSelectedGM.findIndex(x => x.label === chip);
        this.initSelectedGM.splice(index, 1);
      }

      if (name == "spoc") {
        this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
        let index = this.initSelectedSpoc.findIndex(x => x.label === chip);
        this.initSelectedSpoc.splice(index, 1);
      }

      if (name == "level") {
        this.selected_levels.splice(this.selected_levels.indexOf(chip), 1);
        if (this.initSelectedLevels.indexOf(chip) !== -1) {
          this.initSelectedLevels.splice(
            this.initSelectedLevels.indexOf(chip),
            1
          );
        } else {
          let childProperty = "";
          this.treeDataLevels[0].children.forEach((child) => {
            if ("children" in child) {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            }
          });
          this.initSelectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1
          );
          this.treeDataLevels[0].children.forEach((child) => {
            if (child.label === childProperty) {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                  }
                });
              }
            }
          });
        }
      }

      if (name == 'macc') {
        this.selected_macc_levels = [];
        if (this.initSelectedMaccLevels.indexOf(chip) === -1) {
          this.initSelectedMaccLevels = [];
          this.treeDataMaccLevels[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedMaccLevels.push(child.label);
              this.selected_macc_levels.push(child.label);
            }
          });
        } else {
          this.initSelectedMaccLevels.splice(this.initSelectedMaccLevels.indexOf(chip), 1);
          this.selected_macc_levels.splice(this.selected_macc_levels.indexOf(chip), 1);
        }
      }
      if (name == "course") {
        this.selected_courses = []
        if (this.initSelectedCourses.indexOf(chip) === -1) {
          this.initSelectedCourses = [];
          this.treeDataCourses[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCourses.push(child.label);
              this.selected_courses.push(child.label);
            }
          });
        } else {
          this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          this.selected_courses.splice(this.selected_courses.indexOf(chip), 1);
        }
      }
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
  },
};
</script>
<style lang="scss">
.fixTableHead {
  overflow-y: auto;
  max-height: 350px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
}

#no-border {
  border-left: 0px solid transparent !important
}

.mb4 {
  margin-top: 2% !important;
}

#stats_total {
  color: #0044BA;
}

.rwd-table {
  height: 100%;
  margin: 10px auto;
  background-color: white;
  /* above is decorative or flexible */
  position: relative;
  /* could be absolute or relative */
  padding-top: 30px;
  /* height of header */


  width: 98%;
  font-family: 'Montserrat';
  margin: 25px auto;
  border-collapse: collapse;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10),
    0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 20px 20px rgba(0, 0, 0, 0.05),
    0px 30px 20px rgba(0, 0, 0, 0.05);

  tr {
    &:hover {
      background: #f4f4f4;

      td {
        color: #555;
      }
    }
  }

  th,
  td {
    color: #000;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding: 5px 14px;
    border-collapse: collapse;
  }

  th:first-child,
  td:first-child {
    border-left: 0px solid transparent !important;
  }

  th {
    background: #0044ba;
    color: #fff;
    font-size: 12px;

    &.last {
      border-right: none;
    }
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  tr:last-child th:last-child {
    border-top-right-radius: 10px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

#stats {
  th {
    background: #E9E9E9 !important;
    color: #000 !important;
  }
}

div.scrollable {
  max-width: 98px;
  min-height: 30px;
  overflow: auto;
}

.no-padd {
  padding: 0% !important;
}

.roadmap-filter-card .vx-card__collapsible-content .vx-card__body {
  padding: 0px !important;
}

.roadmap-filter-card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.desc-border-box p {
  display: flex;
  justify-content: space-between;
  padding-inline: 22.5px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.desc-border-box2 p {
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 0 0 15px 15px;
  width: 20%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.date_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vs-dropdown--menu {
  width: 300px;
}

.a-iconn {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.a-iconn>.material-icons {
  margin-left: auto;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.ele-side-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.filter {
  margin-left: 4px;
}

.tree-drop {
  margin-top: 0.8%;
}

.tree-drop#city>.vs-con-dropdown {
  background: #caecc5;
  border: 1px solid #a3d79b;
}

.tree-drop#team>.vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid #a394d3;
}

.tree-drop#spoc>.vs-con-dropdown {
  background: #c3edfa;
  border: 1px solid #6cc8e6;
}

.tree-drop#date>.my_date_picker>div>input {
  background: #fffbd5;
  border: 1px solid #e4d650;
  border-radius: 10px;
}

.tree-drop#macc-level>.vs-con-dropdown {
  background: #ffcfb4;
  border: 1px solid #f7d8c6;
}

.tree-drop>.my_date_picker {
  width: 94%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
}

.tree-drop#lvl>.vs-con-dropdown {
  background: #d5ffd9;
  border: 1px solid #5ac765;
  border-radius: 10px;
}

.tree-drop#level>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #f2954a;
  border-radius: 10px;
}

.tree-drop#gm>.vs-con-dropdown {
  background: #d5fff5;
  border: 1px solid #35d6af;
  border-radius: 10px;
}

.tree-drop#eligible>.vs-con-dropdown {
  background: #FFD5D5;
  border: 1px solid #EE7B7B;
  border-radius: 10px;
}

.tree-drop#card2>.vs-con-dropdown {
  border: 1px solid #0044ba;
  width: 280.89px;
  height: 44.47px;
  display: flex;
  border-radius: 15px;
  align-items: center;
  padding-inline: 10px;
}

.tree-drop>.vs-con-dropdown {
  width: 90%;
  min-height: 32px;
  border-radius: 10px;
  // padding: 5.8px;
  padding-inline: 5px;
  // padding-top:3px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.tree-drop>div>.reportrange-text {
  background: #FFFBD5 !important;
  border: 1px solid #E4D650 !important;
  width: 94% !important;
  margin-left: 4%;
  border-radius: 10px;
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.criteria {
  background: #ffffff;
  min-height: 119.65px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: 'Montserrat';
  letter-spacing: 0.3px;
  font-size: 20px;
  font-weight: 900;
  margin-top: 25px
}

.webinar-stats>.vs-card--content {
  padding: 0px !important;
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 1800px) {
  .filter {
    margin-left: 2.8%;
  }
}

@media only screen and (min-width: 1725px) {
  .filter {
    margin-left: 1.4%;
  }
}

@media only screen and (max-width: 1700px) {
  .ne-table {
    th {
      font-size: 14px !important;
    }

    .vs-table--tr {
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 1615px) {
  .reportrange-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1500px) {
  .ne-table {
    th {
      font-size: 13px !important;
    }

    .vs-table--tr {
      font-size: 13px !important;
    }
  }

  .rwd-table {
    tr td:last-child {
      font-size: 12px;
    }
  }

  .reportrange-text {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1400px) {
  .reportrange-text {
    font-size: 9px;
  }
}
</style>
  